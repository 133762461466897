import ComingSoon from "./ComingSoon";

const MedicalDrives = () => {
  return (
      <ComingSoon/>
    // <div>
    //   <h1>Medical Drives</h1>
    // </div>
  );
};

export default MedicalDrives;
